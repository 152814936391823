import { gql } from '@apollo/client';
import { features } from '@config';

export const getSlider = gql`
query getSlider($title: String!){
    slider(
        input: {title: $title}
    ) {
    slider_id
    images {
        image_id
        image_url
        thumb_image_url
        mobile_image_url
        url_redirection
    }
  }
}
`;

export const getBannerSlider = gql`
    {
        getHomepageSlider {
            slider_id
            images {
                image_id
                image_url
                mobile_image_url
                thumb_image_url
                url_redirection
            }
        }
    }
`;

const vendorProductData = `
  vendor_product_data(selectedStore: $vendor_id) {
    vendor_id
    vendor_cost
    vendor_stock
    vendor_special_price
    flash_sale_price
    flash_sale_from
    flash_sale_to
    show_countdown
  }
`;

const categoryTree = `
    id
    name
    path
    image_icon
    image_banner_home
    image_path
    url_path
    products(pageSize: 10) {
        items {
            __typename
            ${vendorProductData}
            id
            name
            url_key
            new_from_date
            new_to_date
            canonical_url
            small_image {
                url(width: ${features.imageSize.product.width}, height: ${features.imageSize.product.height}),
            }
            product_sold
            price_tiers {
                discount {
                    amount_off
                    percent_off
                }
                final_price {
                    currency
                    value
                }
                quantity
            }
            price_range {
                minimum_price {
                    regular_price {
                        currency
                        value
                    }
                    final_price {
                        currency
                        value
                    }
                }
            }
            special_from_date
            special_to_date
        }
    }
`;

export const getFeaturedProducts = gql`
    query($url_key: String!, $vendor_id: Int!) {
        categoryList(filters: { url_key: { eq: $url_key } }) {
            id
            name
            children {
                ${categoryTree}
            }
            children_count
        }
    }
`;

export const getCategoriesByUrlAndVendor = getFeaturedProducts;

export const getFeaturedProductsWithoutChildren = gql`
    query($url_key: String!, $vendor_id: Int!) {
        categoryList(filters: { url_key: { eq: $url_key } }) {
            id
            name
            ${categoryTree}
            children_count
        }
    }
`;

export const getCategoryList = gql`
    query($url_key: String!) {
        categoryList(filters: { url_key: { eq: $url_key } }) {
            children {
                id
                name
                description
                image_path
                url_path
            }
            children_count
        }
    }
`;

export const getHomeCategorySlider = gql`
    query{
        categories {
            home_category {
                id
                image_icon
                name
                url_key
            }
        }
    }
`;

export const getBlogList = gql`
query getAllBlog($page_size: Int, $current_page: Int, $category_id: Int, $id: Int, $url_key: String) {
    getBlogByFilter(
      page_size: $page_size
      current_page: $current_page
      filters: {category_id: $category_id, id: $id, url_key: $url_key}
    ) {
      items {
        id
        title
        blog_post_url
        url_key
        featured_image_url
        updated_at
      }
    }
  }
`;

export const getBestSeller = gql`
query getProductBestSeller($selectedStore: Int!) {
        getProductBestSeller(
        selectedStore: $selectedStore
        pageSize: 20
        currentPage: 1
        ) {
    product {
        id
        product_sold
        weltpixel_labels {
          categoryLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color          
          }
          productLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color  
          }
        }
        name
        sku
        brand
        url_key
        small_image {
            url
            label
            url_original
        }
        description {
            html
        }
        short_description {
            html
        }
        more_info {
            label
            value
        }
        price_range {
            minimum_price {
            discount {
                amount_off
                percent_off
            }
            final_price {
                currency
                value
            }
            fixed_product_taxes {
                amount {
                currency
                value
                }
                label
            }
            regular_price {
                currency
                value
            }
            }
            maximum_price {
            discount {
                amount_off
                percent_off
            }
            final_price {
                currency
                value
            }
            fixed_product_taxes {
                amount {
                currency
                value
                }
                label
            }
            regular_price {
                currency
                value
            }
            }
        }
        }
        vendor_product_data {
        vendor_id
        product_id
        vendor_cost
        vendor_stock
        vendor_special_price
        flash_sale_price
        flash_sale_from
        flash_sale_to
        show_countdown
        special_price
        preorder
        preorder_time
        preorder_time_config
        }
    }
}
`;

export const getPreOrderProducts = gql`
query getProductPreOrder($selectedStore: Int!) {
    getProductPreOrder(
      selectedStore: $selectedStore
      pageSize: 10
      currentPage: 1
    ) {
      product {
        id
        product_sold
        weltpixel_labels {
          categoryLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color          
          }
          productLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color  
          }
        }
        name
        sku
        brand
        url_key
        small_image {
            url
            label
            url_original
        }
        description {
            html
        }
        short_description {
            html
        }
        more_info {
            label
            value
        }
        price_range {
            minimum_price {
            discount {
                amount_off
                percent_off
            }
            final_price {
                currency
                value
            }
            fixed_product_taxes {
                amount {
                currency
                value
                }
                label
            }
            regular_price {
                currency
                value
            }
            }
            maximum_price {
            discount {
                amount_off
                percent_off
            }
            final_price {
                currency
                value
            }
            fixed_product_taxes {
                amount {
                currency
                value
                }
                label
            }
            regular_price {
                currency
                value
            }
            }
        }
        }
        vendor_product_data {
        vendor_id
        product_id
        vendor_cost
        vendor_stock
        vendor_special_price
        flash_sale_price
        flash_sale_from
        flash_sale_to
        show_countdown
        special_price
        preorder
        preorder_time
        preorder_time_config
        }
    }
}
`;

export const getDataTopSellerProducts = gql`
    query getTopSellerProducts($selectedStore: Int!) {
      getTopSellerProducts(
        selectedStore: $selectedStore,
        pageSize: 10,
        currentPage: 1
      ) {
        product {
          id
          product_sold
          weltpixel_labels {
            categoryLabel {
              css
              customer_group
              image
              page_position
              position
              priority
              text
              text_padding
              text_bg_color
              text_font_size
              text_font_color          
            }
            productLabel {
              css
              customer_group
              image
              page_position
              position
              priority
              text
              text_padding
              text_bg_color
              text_font_size
              text_font_color  
            }
          }
          name
          sku
          brand
          url_key
          small_image {
            url
            label
            url_original
          }
          description {
            html
          }
          short_description {
            html
          }
          more_info {
            label
            value
          }
          price_range {
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              fixed_product_taxes {
                amount {
                  currency
                  value
                }
                label
              }
              regular_price {
                currency
                value
              }
            }
            maximum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              fixed_product_taxes {
                amount {
                  currency
                  value
                }
                label
              }
              regular_price {
                currency
                value
              }
            }
          }
        }
        vendor_product_data {
          vendor_id
          product_id
          vendor_cost
          vendor_stock
          vendor_special_price
          flash_sale_price
          flash_sale_from
          flash_sale_to
          show_countdown
          special_price
          preorder
          preorder_time
          preorder_time_config
        }
      }
    }
`;

export const getLastViewedProductsSchema = gql`
  query getLastViewedProducts($selectedStore: Int!) {
    getLastViewedProducts(
      selectedStore: $selectedStore
      pageSize: 10
      currentPage: 1
    ) {
      product {
        id
        weltpixel_labels {
          categoryLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color
            __typename
          }
          productLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color
            __typename
          }
          __typename
        }
        name
        sku
        brand
        url_key
        small_image {
          url
          label
          url_original
          __typename
        }
        description {
          html
          __typename
        }
        short_description {
          html
          __typename
        }
        more_info {
          label
          value
          __typename
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
              __typename
            }
            final_price {
              currency
              value
              __typename
            }
            fixed_product_taxes {
              amount {
                currency
                value
                __typename
              }
              label
              __typename
            }
            regular_price {
              currency
              value
              __typename
            }
            __typename
          }
          maximum_price {
            discount {
              amount_off
              percent_off
              __typename
            }
            final_price {
              currency
              value
              __typename
            }
            fixed_product_taxes {
              amount {
                currency
                value
                __typename
              }
              label
              __typename
            }
            regular_price {
              currency
              value
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      vendor_product_data {
        vendor_id
        product_id
        vendor_cost
        vendor_stock
        vendor_special_price
        flash_sale_price
        flash_sale_from
        flash_sale_to
        show_countdown
        special_price
        preorder
        preorder_time
        preorder_time_config
        __typename
      }
      __typename
    }
  }
`;

export const getDataNewArrivalProducts = gql`
    query getNewArrivalProducts($selectedStore: Int!) {
      getNewArrivalProducts(
        selectedStore: $selectedStore,
        pageSize: 10,
        currentPage: 1
      ) {
        product {
          id
          product_sold
          weltpixel_labels {
            categoryLabel {
              css
              customer_group
              image
              page_position
              position
              priority
              text
              text_padding
              text_bg_color
              text_font_size
              text_font_color          
            }
            productLabel {
              css
              customer_group
              image
              page_position
              position
              priority
              text
              text_padding
              text_bg_color
              text_font_size
              text_font_color  
            }
          }
          name
          sku
          brand
          url_key
          small_image {
            url
            label
            url_original
          }
          description {
            html
          }
          short_description {
            html
          }
          more_info {
            label
            value
          }
          price_range {
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              fixed_product_taxes {
                amount {
                  currency
                  value
                }
                label
              }
              regular_price {
                currency
                value
              }
            }
            maximum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              fixed_product_taxes {
                amount {
                  currency
                  value
                }
                label
              }
              regular_price {
                currency
                value
              }
            }
          }
        }
        vendor_product_data {
          vendor_id
          product_id
          vendor_cost
          vendor_stock
          vendor_special_price
          flash_sale_price
          flash_sale_from
          flash_sale_to
          show_countdown
          special_price
          preorder
          preorder_time
          preorder_time_config
        }
      }
    }
`;

export const getDataMostPopularProducts = gql`
    query getMostPopularProducts($selectedStore: Int!) {
      getMostPopularProducts(
        selectedStore: $selectedStore,
        pageSize: 10,
        currentPage: 1
      ) {
        product {
          id
          product_sold
          weltpixel_labels {
            categoryLabel {
              css
              customer_group
              image
              page_position
              position
              priority
              text
              text_padding
              text_bg_color
              text_font_size
              text_font_color          
            }
            productLabel {
              css
              customer_group
              image
              page_position
              position
              priority
              text
              text_padding
              text_bg_color
              text_font_size
              text_font_color  
            }
          }
          name
          sku
          brand
          url_key
          small_image {
            url
            label
            url_original
          }
          description {
            html
          }
          short_description {
            html
          }
          more_info {
            label
            value
          }
          price_range {
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              fixed_product_taxes {
                amount {
                  currency
                  value
                }
                label
              }
              regular_price {
                currency
                value
              }
            }
            maximum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              fixed_product_taxes {
                amount {
                  currency
                  value
                }
                label
              }
              regular_price {
                currency
                value
              }
            }
          }
        }
        vendor_product_data {
          vendor_id
          product_id
          vendor_cost
          vendor_stock
          vendor_special_price
          flash_sale_price
          flash_sale_from
          flash_sale_to
          show_countdown
          special_price
          preorder
          preorder_time
          preorder_time_config
        }
      }
    }
`;

export const getShopvaganzaSchema = gql`
  query getShopvaganzaConfig($cartId: String!) {
    calculationShopVaganza(
      input: {cart_id: $cartId}
    ){
        enable
    }
  }
`;

export default {
    getBannerSlider,
    getCategoryList,
    getFeaturedProducts,
    getFeaturedProductsWithoutChildren,
    getSlider,
    getHomeCategorySlider,
    getBlogList,
    getBestSeller,
    getPreOrderProducts,
    getDataTopSellerProducts,
    getDataNewArrivalProducts,
    getDataMostPopularProducts,
};
