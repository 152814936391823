import { useQuery } from '@apollo/client';
import * as Schema from './schema';

let fetchPolicy = '';
fetchPolicy = 'cache-first';
// uncomment this line (set 'no-cache') to test loader/skeleton component
// fetchPolicy = 'no-cache';

export const getBannerSlider = () => useQuery(Schema.getBannerSlider, {
    fetchPolicy,
});

export const getHomeCategorySlider = () => useQuery(Schema.getHomeCategorySlider, {
    fetchPolicy,
});

export const getSlider = (options = {}) => useQuery(Schema.getSlider, {
    fetchPolicy,
    ...options,
});

export const getFeaturedProducts = (options) => useQuery(Schema.getFeaturedProducts, {
    fetchPolicy: 'no-cache',
    context: { request: 'internal' },
    ...options,
});

export const getCategoriesByUrlAndVendor = (options) => useQuery(Schema.getCategoriesByUrlAndVendor, {
    ...options,
    fetchPolicy,
});

export const getShopVaganzaConfig = (options) => useQuery(Schema.getShopvaganzaSchema, {
    ...options,
    context: { request: 'internal' },
    fetchPolicy: 'network-only',
});

export const getFeaturedProductsWithoutChildren = (options) => useQuery(Schema.getFeaturedProductsWithoutChildren, {
    ...options,
    fetchPolicy,
});

export const getCategoryList = (variables) => useQuery(Schema.getCategoryList, {
    variables,
    fetchPolicy,
});

export const getBlogList = (variables) => useQuery(Schema.getBlogList, {
    variables,
    fetchPolicy,
});

export const getBestSeller = (variables) => useQuery(Schema.getBestSeller, {
    variables,
    fetchPolicy,
});

export const getPreOrderProducts = (variables) => useQuery(Schema.getPreOrderProducts, {
    variables,
    fetchPolicy,
});

export const getDataTopSellerProducts = (variables) => useQuery(Schema.getDataTopSellerProducts, {
    ...variables,
    fetchPolicy: 'no-cache',
});

export const getLastViewedProducts = (variables) => useQuery(Schema.getLastViewedProductsSchema, {
    variables,
    fetchPolicy: 'no-cache',
    context: {
        request: 'internal',
    },
});

export const getDataNewArrivalProducts = (variables) => useQuery(Schema.getDataNewArrivalProducts, {
    variables,
    fetchPolicy: 'no-cache',
});

export const getDataMostPopularProducts = (variables) => useQuery(Schema.getDataMostPopularProducts, {
    variables,
    fetchPolicy: 'no-cache',
});

export default {
    getCategoryList,
    getBannerSlider,
    getFeaturedProducts,
    getFeaturedProductsWithoutChildren,
    getSlider,
    getHomeCategorySlider,
    getBlogList,
    getBestSeller,
    getPreOrderProducts,
    getDataTopSellerProducts,
    getLastViewedProducts,
    getDataNewArrivalProducts,
    getDataMostPopularProducts,
    getCategoriesByUrlAndVendor,
};
